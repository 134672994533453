import request from './request'
import Bookshelf from 'models/bookshelf'

export async function getBookshelf(): Promise<Bookshelf> {
  try {
    const res = await request(`api/v1/bookshelfapp/bookshelf/list`)
    return res?.data?.data
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export async function removeBookshelf(id: string[]): Promise<number> {
  try {
    const res = await request.delete(`/api/v1/bookshelfapp/bookshelf/del`, {
      data: {
        bookIds: id,
      },
    })
    return res?.data?.data
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export async function putBookshelf({
  books,
}: {
  books: any[]
}): Promise<string> {
  try {
    const res = await request.post(
      `/api/v1/bookshelfapp/bookshelf/sync`,
      { books },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return res?.data?.data
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export default {
  getBookshelf,
  removeBookshelf,
  putBookshelf,
}
