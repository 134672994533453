import axios from 'axios'
import { HOST, headers } from 'config'
import { getUserInfoCache } from '../../utils/user-info'

const request = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : HOST,
  timeout: 10000,
  responseType: 'json',
  withCredentials: true,
})

request.interceptors.request.use(
  (config) => {
    if (process.browser) {
      const userInfo = getUserInfoCache()
      if (userInfo) {
        headers['access-token'] = userInfo.token
        // headers['X-Cid'] = userInfo.id
        headers['X-userId'] = userInfo.id
      }
      headers['X-CCid'] =
        localStorage.getItem('visitorId') ?? '000000000000000000000000'
      headers['X-Cid'] =
        localStorage.getItem('visitorId') ?? '000000000000000000000000'
      config.headers = { ...config.headers, ...headers }
    } else {
      config.headers = { ...headers, ...config.headers }
      // eslint-disable-next-line
      // console.log(config.headers)
    }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default request
