import request from './request'
import BookListItem from 'models/book-list-item'
import BookDetails from 'models/book-details'

export async function getPageNodeBookList(): Promise<any> {
  try {
    const pages = await request(`/api/v2/bookapp/home`)
    if (pages?.data?.data?.length > 0) {
      const res = await request(`/api/v2/bookapp/home/nodelist`, {
        params: {
          nodeId: pages.data.data[0]['id'],
          pageIndex: 1,
          pageSize: 20,
        },
      })
      return res?.data?.data?.data || []
    }
    return []
  } catch (err) {
    if (err.response?.status !== 200) return []
    throw err
  }
}

export async function getNodeBookList({
  nodeId,
  pageIndex = 1,
  pageSize = 200,
}: {
  nodeId: string
  pageIndex?: number
  pageSize?: number
}): Promise<any> {
  try {
    const res = await request(`/api/v2/bookapp/home/nodebooklist`, {
      params: {
        nodeId,
        pageIndex,
        pageSize,
      },
    })
    return res?.data?.data?.data || []
  } catch (err) {
    if (err.response?.status !== 200) return []
  }
}

export async function getBookSkuId(id: string): Promise<any> {
  return id
}

export async function getBookInfo(id: string): Promise<BookDetails> {
  try {
    const bookSkuId = await getBookSkuId(id)
    const res = await request(`/api/v2/bookapp/book/${bookSkuId}/profile`)
    return res.data.data
  } catch (err) {
    if (err.response?.status !== 200) return null
  }
}

export async function getBookList({
  type,
  offset = 0,
  limit = 10,
  isHome = false,
}: {
  type: 'hot' | 'new' | 'bestseller'
  offset?: number
  limit?: number
  isHome?: boolean
}): Promise<BookListItem[]> {
  try {
    return (
      (
        await request(`/api/v1/books/list/${type}`, {
          params: {
            offset,
            limit,
            isHome,
          },
        })
      ).data || []
    )
  } catch (err) {
    if (err.response?.status !== 200) return []
    throw err
  }
}

export async function getGenreBookList({
  id,
  offset = 0,
  limit = 10,
}: {
  id: string
  offset?: number
  limit?: number
}): Promise<BookListItem[]> {
  try {
    return (
      await request(`/api/v1/books/list/genre/${id}`, {
        params: {
          offset,
          limit,
        },
      })
    ).data
  } catch (err) {
    if (err.response?.status !== 200) return []
    throw err
  }
}

export async function getTagBookList({
  tagName,
  pageIndex = 1,
  pageSize = 10,
}: {
  tagName?: string
  pageIndex?: number
  pageSize?: number
}): Promise<any> {
  try {
    const res = await request(`/api/v2/bookapp/book/tagbooklist`, {
      params: {
        tagName,
        pageIndex,
        pageSize,
      },
    })
    return res?.data?.data || []
  } catch (err) {
    if (err.response?.status !== 200) return []
    throw err
  }
}

export async function getPurchasedBookList({
  offset = 0,
  limit = 10,
}: {
  offset?: number
  limit?: number
}): Promise<any[]> {
  try {
    const res = await request(`/api/v2/userapp/book/unlocked/list`, {
      params: {
        offset,
        limit,
      },
    })
    return res?.data?.data || []
  } catch (err) {
    if (err.response?.status !== 200) return []
  }
}

export default {
  getPageNodeBookList,
  getNodeBookList,
  getBookInfo,
  getBookList,
  getGenreBookList,
  getTagBookList,
  getPurchasedBookList,
}
