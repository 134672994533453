import request from './request'
import User from 'models/user'

export async function getUserInfo(): Promise<User> {
  try {
    const res = await request(`/api/v1/userapp/auser/userinfo`)
    return res?.data?.data || null
  } catch (err) {
    if (err.response?.status === 400) return null
    throw err
  }
}

export default {
  getUserInfo,
}
